.bottom-navigation {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  .MuiBottomNavigationAction-label {
    font-size: 10px;
    line-height: 12px;
    margin-top: 2px;
    font-weight: 600;
    color: #212121;
    &.Mui-selected {
      font-size: 10px;
      color: #ed9720;
    }
  }
  .MuiBottomNavigationAction-root {
    padding: 0px;
    &.Mui-selected {
      padding-top: 0px;
      color: #ed9720 !important;
    }
    &.MuiSvgIcon-root {
      color: #212121;
    }
  }
}
.bottom-navigation.MuiBottomNavigation-label {
  padding: 2px 0px;
  align-items: center;
  box-shadow: 0px 1px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px -1px 10px 0px rgba(0, 0, 0, 0.12);
}

.fixed-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width: 599px) {
    position: fixed;
    bottom: 0;
    right: 0;
  }
  .footer-btn {
    margin: 0 auto;
    align-items: center;

    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    // @media screen and (min-width: 600px) {
    //   justify-content: flex-end;
    //   .MuiButtonBase-root {
    //     margin-left: 18px;
    //   }
    // }
  }
}

.payment-footer-wrapper {
  width: 100%;
  position: fixed;
  bottom: 58px;
  right: 0;
  background-color: #bfd3ff;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0), 0 1px 10px 2px rgba(0, 0, 0, 0.05), 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  .footer-btn {
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
  }
}
